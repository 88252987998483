import { Show, createEffect, createSignal, splitProps } from "solid-js";
import { DropdownProps } from "./model";

export function Dropdown(props: DropdownProps) {
  const [dropMenu, setDropMenu] = createSignal(false);
  const [dropMenuValue, setDropMenuValue] = createSignal("");

  createEffect(() => {
    const opt_ans = props.elements?.opt_ans;
    if (opt_ans === undefined || opt_ans === null) {
      return;
    }
    const opt_item = props.elements?.options?.find((x) => x.id === opt_ans);
    // console.log("opt_item:: from drop down", opt_item);
    if (!opt_item) {
      console.error("Task Atom Dropdown: opt_item not found, id :: ", opt_ans);
      return;
    }
    setDropMenuValue(opt_item.value);
  });

  const toggleDropMenu = () => {
    setDropMenu(!dropMenu());
  };
  const handleDropMenuValue = (id, value) => {
    setDropMenuValue(value);
    props?.events?.onValueChange?.(id);
    setDropMenu(!dropMenu());
  };
  return (
    <Show
      when={props.options?.editable}
      fallback={
        <div
          class={`flex justify-between items-center text-16px border-solid border-2 border#p p-15px h-22px cursor-pointer ${
            props.class ?? ""
          }`}
        >
          <input
            id="dropdown"
            readOnly
            placeholder={props.placeholder}
            value={dropMenuValue()}
            class="cursor-pointer bg-inherit placeholder:text#p"
          />
          <i class={`icon-local:arrow-right w-23px h-23px rotate-90`} />
        </div>
      }
    >
      <section class="relative flex flex-col gap-5px">
        <div
          onClick={toggleDropMenu}
          class={`relative flex justify-between items-center text-16px border-solid border-2 border#n-200 p-15px h-22px cursor-pointer  ${
            props.class ?? ""
          } z-100 `}
        >
          <input
            id="dropdown"
            readOnly
            placeholder={props.placeholder}
            class="outline-none !rounded-sm absolute cursor-pointer bg-inherit placeholder:text#n-200 placeholder:text-17px text-17px text#n-200 "
            value={dropMenuValue()}
            tabIndex="-1"
          />
          <i
            class={
              dropMenu()
                ? `icon-local:arrow-right w-23px h-23px  rotate-270  absolute right-5px duration-300 text#n-200`
                : `icon-local:arrow-right w-23px h-23px rotate-90 absolute right-5px duration-300 text#n-200`
            }
          />
        </div>

        <div
          class={`
        flex flex-col gap-10px p-15px w-full text-16px text#n-200 bg#paper rounded-sm border border-2 border-solid border#n-200 overflow-hidden 
        absolute mt-9 z-200
        ${dropMenu() ? "" : "!hidden"}`}
        >
          {props.elements?.options?.map((opt) => {
            return (
              <div
                onClick={() => handleDropMenuValue(opt.id, opt.value)}
                class="cursor-pointer border-b-2 border-white border-solid  duration-300  w-full !hover:text#p"
              >
                {opt.value}
              </div>
            );
          })}
        </div>
      </section>
    </Show>
  );
}
